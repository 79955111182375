<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="product.length > 0" wrap>
      <v-flex
        pa-6
        xs12
        xl11
        v-for="item in product"
        :key="item._id"
        pr-lg-6
        pr-xl-0
      >
        <v-layout wrap>
          <v-flex xs12>
            <v-layout wrap justify-start pb-4>
              <v-flex lg12 text-left>
                <span style="font-size: 16px; color: #000000">
                  {{ item.productname }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg6>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12 align-self-start>
                <v-layout wrap justify-start>
                  <v-flex xs4 sm4 md4 lg4 xl4>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span style="color: #8d8d8d; font-size: 14px">
                          Name
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span style="font-size: 16px">
                          {{ item.firstname }}
                          {{ item.lastname }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4 sm4 md4 lg4 xl4>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span style="color: #8d8d8d; font-size: 14px">
                          Phone
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span style="font-size: 16px">
                          {{ item.mobile }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4 sm4 md4 lg4 xl4>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span style="color: #8d8d8d; font-size: 14px">
                          Amount
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span style="font-size: 16px">
                          Rs. {{ item.amount }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-left xs12 md12 lg6>
            <v-layout wrap justify-space-between>
              <v-flex sm3 md3 lg3 xl3>
                <v-layout wrap fill-height>
                  <v-flex xs6 sm12>
                    <span style="color: #8d8d8d; font-size: 14px">
                      Delivery Status
                    </span>
                  </v-flex>
                  <v-flex xs6 sm12 text-left align-self-start>
                    <span
                      v-if="item.details.length > 0"
                      style="font-size: 16px"
                    >
                      {{ item.details[0].deliverystatus }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex sm3 md3 lg3 xl3>
                <v-layout wrap fill-height>
                  <v-flex xs6 sm12>
                    <span style="color: #8d8d8d; font-size: 14px">
                      Purchase Type
                    </span>
                  </v-flex>
                  <v-flex xs6 sm12 text-left align-self-start>
                    <span style="font-size: 16px">
                      {{ item.purchasetype }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex sm3 md3 lg3 xl3>
                <v-layout wrap fill-height>
                  <v-flex xs6 sm12>
                    <span style="color: #8d8d8d; font-size: 14px">
                      Discount
                    </span>
                  </v-flex>
                  <v-flex xs6 sm12 text-left align-self-start>
                    <span style="font-size: 16px">
                      {{ item.discount }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 lg3 sm3 md3 text-center align-self-end>
                <v-btn
                  tile
                  block
                  small
                  outlined
                  color="warning"
                  style="text-transform: none"
                  @click="
                    $router.push({
                      name: 'purchaseDetails',
                      params: { purchaseid: item._id },
                    })
                  "
                >
                  <span style="color: #000; font-size: 14px"> View </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex v-for="(productItem, i) in item.product" :key="i" md3 pa-4>
            <v-card
              tile
              flat
              style="cursor: pointer"
              @click="info(productItem._id)"
              ><br />
              <v-img
                :src="baseURL + productItem.photos[0]"
                height="200px"
                contain
              ></v-img>
              <v-layout wrap justify-center px-2>
                <v-flex
                  pt-2
                  xl12
                  text-left
                  style="line-height: 16px; cursor: pointer"
                  @click="info(productItem._id)"
                >
                  <span style="font-size: 13px; color: #000000">
                    {{ productItem.productname }}
                  </span>
                  <br /><br />
                  <!-- <span style="font-size: 13px; color: #000000">
                                {{ productItem.subcategory.name }}
                              </span>
                              <br /> -->
                  <span style="font-size: 13px; color: #000000">
                    Rs. {{ productItem.price }}
                  </span>
                  <span
                    class="pl-12 text-decoration-line-through"
                    style="font-size: 13px; color: #ff0000"
                    v-if="productItem.offer > 0"
                  >
                    Rs. {{ productItem.orginalprice }}
                  </span>
                  <br />
                  <span>
                    <v-rating
                      v-model="productItem.rating"
                      color="#FFD341"
                      background-color="#e0e0e0"
                      empty-icon="$ratingFull"
                      half-increments
                      readonly
                      hover
                      small
                    ></v-rating>
                  </span>
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <div class="pt-2">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#FF0000"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-card v-else flat height="20vh">
      <v-layout wrap justify-center fill-height>
        <v-flex text-center align-self-center>
          <span>No Purchases Found !!</span>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["userid"],
  data() {
    return {
      appLoading: false,
      product: [],
      photos: [],
      currentPage: 1,
      pages: 0,
      count: 5,
      totalData: 0,
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.productInfo();
  },
  methods: {
    productInfo() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/reportadmin",
        params: {
          buyer: this.userid,
          count: this.count,
          page: this.currentPage,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.product = response.data.data;
            this.pages = response.data.pages;
            this.totalData = response.data.count;
            this.appLoading = false;
          } else {
            this.$emit("stepper", {
              msg: response.data.msg,
              showsnackbar: true,
            });
            this.appLoading = false;
          }
        })
        .catch((e) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(e);
        });
    },
    info(pid) {
      this.$router.push("/productDetails/" + pid);
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>