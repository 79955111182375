<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            User Details
            <v-spacer></v-spacer>
          </v-card-title>
          <div v-if="user">
            <v-row
              class="py-2 justify-content-md-center"
              style="background-color: #b0a7a8"
            >
              <v-col cols="12" class="pa-10">
                <v-row>
                  <v-col cols="12" md="2">
                    <div v-viewer>
                      <v-avatar
                        style="cursor: pointer"
                        v-if="user.image"
                        size="150"
                      >
                        <img :src="baseURL + user.image" />
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="10" sm="12" class="pa-10" style="">
                    <h1 style="color: red">
                      {{ user.firstname }} {{ user.lastname }}
                    </h1>
                    <h5 style="color: red">{{ user.email }}</h5>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-tabs
              v-model="tab"
              background-color="white"
              color="red"
              grow
              class="pt-5"
            >
              <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
              <!-- <v-tabs-items v-model="tab"> -->
              <v-tab-item>
                <v-row class="pt-8">
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">First Name</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{
                          user.firstname
                        }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Last Name</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.lastname }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Username</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.username }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Gender</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.gender }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Phone</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.phone }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="4" md="5">
                        <span class="item_title">Email</span>
                      </v-col>
                      <v-col cols="8" md="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.email }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">User Status</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.status }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Role</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.type }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Account Created On</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ formatDate(user.account_create_at) }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <div class="my-5">
                    <template>
                      <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-on="on" v-bind="attrs" outlined color="red"
                            >Remove User</v-btn
                          >
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to remove this
                            User?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteuser()"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </div>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <PurchaseDetails @stepper="winStepper" :userid="userid" />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import PurchaseDetails from "./purchaseDetails";
export default {
  props: ["userid"],
  components: { PurchaseDetails },
  data() {
    return {
      msg: null,
      ServerError: false,
      showsnackbar: false,
      user: [],
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      tab: null,
      items: [" Profile Details", " Purchase Details"],
      product: [],
      photos: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(winStep) {
      if (winStep.showsnackbar) {
        this.showsnackbar = true;
        this.msg = winStep.msg;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/info",
        params: {
          id: this.userid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            // this.userStatus = this.user.status;
            // console.log(this.user);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteuser() {
      axios({
        url: "/user/remove",
        method: "GET",
        params: {
          id: this.userid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>